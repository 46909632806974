export default {
  features: {
    createDelivery: {
      panelLink: {
        title: 'Доставки',
        link: 'Доставка Алифа',
      },
    },
  },
};
