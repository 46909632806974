export default {
  features: {
    sellToAlif: {
      sellToAlifPanel: {
        desc: [
          'ПРЕДЛОЖЕНИЕ ОТ ООО "Alif Moliya"',
          'ООО "Alif Moliya" покупает указанный(ые) в заявке товар(ы) по согласованной цене. Нажимая кнопку «Продать Алифу», вы принимаете данное предложение и передаете ООО "Alif Moliya" право собственности на продукцию, указанную в заявке.',
        ],
        button: 'Продать Алифу',
      },
      cancelSellToAlifPanel: {
        desc: 'Право собственности на данные товары успешно передано ООО "Alif Moliya"',
        button: 'Отменить',
      },
    },
  },
};
