import { DELIVERY_STATUS, DELIVERY_TYPE, DELIVERY_TYPE_SERVICE } from '@/to-fsd/entities/delivery/constants';

export default {
  entities: {
    delivery: {
      generateFargoWaybill: "Yo'l xatni yuklash",
      statusText: {
        [DELIVERY_STATUS.NEW]: 'Yangi',
        [DELIVERY_STATUS.ORDER_ACCEPTED]: 'Buyurtma qabul qilindi',
        [DELIVERY_STATUS.COURIER_PICKED_UP]: 'Kuryer tovarni oldi',
        [DELIVERY_STATUS.DELIVERED]: 'Yetkazib berildi',
        [DELIVERY_STATUS.CANCELLED]: 'Bekor qilindi',
      },
      deliveryType: {
        all: 'Hammasi',
        [DELIVERY_TYPE.ALIF_DELIVERY]: 'Alif Delivery',
        [DELIVERY_TYPE.DPD_DELIVERY]: 'DPD',
        [DELIVERY_TYPE.FARGO]: 'Fargo',
        [DELIVERY_TYPE.UZ_POST]: 'Uzpost',
        [DELIVERY_TYPE.NESUVEZU]: 'NesuVezu',
      },
      deliveryInfo: {
        title: 'Yetkazib berishlar',
        deliveryNumber: 'Yetkazib berish №{number}',
        modal: {
          title: 'Yetkazib berish №{number}',
          timeToDelivery: 'Yetkazib berishga qolgan vaqt',
          senderAddress: "Jo'natuvchi manzili:",
          receiverAddress: 'Qabul qiluvchi manzili:',
          closeButton: 'Tushunman',
        },
      },
      dpdDeliveryTypeService: {
        [DELIVERY_TYPE_SERVICE.ENTER_ENTER]: 'Eshikdan eshikkacha',
        [DELIVERY_TYPE_SERVICE.ENTER_POINT]: 'Eshikdan buyurtma berish punktigacha',
        [DELIVERY_TYPE_SERVICE.POINT_POINT]: 'Buyurtma berish punktidan buyurtma berish punktigacha',
        [DELIVERY_TYPE_SERVICE.POINT_ENTER]: 'Buyurtma berish punktidan eshikkacha',
      },
      deliveryReceiverInfo: {
        name: 'Mijoz ismi',
        phone: 'Telefon raqami',
      },
      approximateTime: {
        text: 'Tahminiy yetkazib berish vaqti',
      },
      senderAndReceiverAddress: {
        sender: 'Yuboruvchi manzili',
        receiver: 'Qabul qiluvchi manzili',
      },
      deliveryCreatedAlert: {
        panel: {
          title: 'Alif yetkazmasi',
        },
        alert: 'Ushbu ariza uchun yetkazma yaratilgan',
      },
    },
  },
};
