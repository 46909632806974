<template>
  <template v-if="queries.creditsApp.isLoading || queries.warehouseApp.isLoading">
    <fullscreen-loader />
  </template>

  <template v-else-if="queries.creditsApp.isError || queries.warehouseApp.isError">
    <api-error :error="queries.creditsApp.error" />
    <api-error :error="queries.warehouseApp.error" />
  </template>

  <template v-else>
    <nasiya-layout-new v-if="isNewOnboarding" />

    <nasiya-layout-old v-else />
  </template>

  <reload-page-on-deploy-modal />
</template>

<script setup>
  import { reactive } from 'vue';

  import NasiyaLayoutNew from './NasiyaLayoutNew.vue';
  import NasiyaLayoutOld from './NasiyaLayoutOld.vue';

  import { useIsNewOnboarding } from '@/modules/main/common/stores';
  import { useGetCreditsAppQuery, useGetWarehouseAppQuery } from '@/to-fsd/shared/queries';
  import { FullscreenLoader } from '@/to-fsd/shared/ui';
  import { ApiError } from '@/to-fsd/shared/lib';
  import { ReloadPageOnDeployModal } from '@/to-fsd/features/reload-page-on-deploy';

  const queries = reactive({
    creditsApp: useGetCreditsAppQuery(),
    warehouseApp: useGetWarehouseAppQuery(),
  });

  const isNewOnboarding = useIsNewOnboarding();
</script>
