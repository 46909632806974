export default {
  pages: {
    moderationRequests: {
      title: "So'rovlar",
      dropdown: {
        label: "Tovar qo'shish",
        items: ["Qo'lda qo'shish", 'Excel yuklash'],
      },
    },
  },
};
