export default {
  features: {
    createHoldDownPayment: {
      button: 'Внести предоплату',
      modal: {
        title: 'Внести предоплату',
        subTitle: 'Сумма предоплаты не может быть меньше, чем разница между суммой покупки и лимитом клиента',
        amount: {
          label: 'Сумма предоплаты',
          placeholder: '0',
          alert: 'Предпочитаемая сумма оплаты',
        },
        cancel: 'Отменить',
        save: 'Оплатить',
      },
      successAlert: 'Оплата успешно проведена',
    },
  },
};
