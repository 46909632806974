export default {
  widgets: {
    requestApplicationApprovalPanel: {
      title: 'Все шаги успешно заполнены',
      signed: 'Соглашение было подписано. Подписал(а) — {name}.',
      canBeSent: 'Можно отправить его на рассмотрение.',
      hintDownPayment:
        'Если лимита не достаточно для приобретения данного товара — предложите клиенту внести предоплату',
    },
  },
};
