<template>
  <at-modal v-model="showModal" size="xl" @update:model-value="!$event && handleModalClose()">
    <template v-if="image" #default>
      <div class="mt-6">
        <vue-cropper
          v-if="image"
          ref="cropper"
          :key="cropperIndex"
          :src="imageUrl"
          :auto-crop-area="1"
          :zoom-on-touch="false"
          :zoom-on-wheel="false"
          :img-style="{ 'max-height': '70vh' }"
          preview=".preview"
        />
      </div>
    </template>

    <template v-if="image" #footer>
      <div class="flex items-center justify-center -m-2">
        <div class="p-2">
          <at-button color="secondary" type="button" @click="$refs.cropper?.relativeZoom(0.2)">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="22"
              height="22"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              stroke-width="2"
              stroke-linecap="round"
              stroke-linejoin="round"
            >
              <circle cx="11" cy="11" r="8" />
              <line x1="21" y1="21" x2="16.65" y2="16.65" />
              <line x1="11" y1="8" x2="11" y2="14" />
              <line x1="8" y1="11" x2="14" y2="11" />
            </svg>
          </at-button>
        </div>
        <div class="p-2">
          <at-button color="secondary" type="button" @click="$refs.cropper?.relativeZoom(-0.2)">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="22"
              height="22"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              stroke-width="2"
              stroke-linecap="round"
              stroke-linejoin="round"
            >
              <circle cx="11" cy="11" r="8" />
              <line x1="21" y1="21" x2="16.65" y2="16.65" />
              <line x1="8" y1="11" x2="14" y2="11" />
            </svg>
          </at-button>
        </div>
        <div class="p-2">
          <at-button color="secondary" type="button" @click="$refs.cropper?.rotate(-90)">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="22"
              height="22"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              stroke-width="2"
              stroke-linecap="round"
              stroke-linejoin="round"
            >
              <polyline points="1 4 1 10 7 10" />
              <path d="M3.51 15a9 9 0 1 0 2.13-9.36L1 10" />
            </svg>
          </at-button>
        </div>
        <div class="p-2">
          <at-button color="secondary" type="button" @click="$refs.cropper?.rotate(90)">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="22"
              height="22"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              stroke-width="2"
              stroke-linecap="round"
              stroke-linejoin="round"
            >
              <polyline points="23 4 23 10 17 10" />
              <path d="M20.49 15a9 9 0 1 1-2.12-9.36L23 10" />
            </svg>
          </at-button>
        </div>
        <div class="p-2">
          <at-button color="primary" type="button" @click="handleSave">
            {{ $t('clients.requirements.passport.save') }}
          </at-button>
        </div>
      </div>
    </template>
  </at-modal>
</template>

<script setup>
  import { computed, ref } from 'vue';

  import VueCropper from '@/plugins/cropper/VueCropper';

  import { AtModal, AtButton } from '@packages/aliftech-ui';

  const showModal = ref(false);
  const image = ref(null);
  const cropperIndex = ref(0);
  const cropper = ref(null);

  const imageUrl = computed(() => {
    return URL.createObjectURL(image.value);
  });

  let promise = null;
  let promiseResolve = null;

  const initPromise = () => {
    promise = new Promise(res => {
      promiseResolve = res;
    });
  };

  const handleSave = async () => {
    const url = cropper.value.getCroppedCanvas().toDataURL('image/jpeg', 0.8);

    const response = await fetch(url);

    const blob = await response.blob();

    const file = new File([blob], image.value.name, {
      type: image.value.type,
    });

    showModal.value = false;

    promiseResolve(file);
  };

  const handleModalClose = () => {
    promiseResolve(null);
  };

  defineExpose({
    open(_image) {
      image.value = _image;
      showModal.value = true;
      cropperIndex.value++;
      initPromise();
      return promise;
    },
  });
</script>
