export default {
  widgets: {
    deliveriesListTable: {
      headers: ['ID', 'Клиент', 'Тип доставки', 'Статус', 'Дата'],
      filtersBlock: {
        deliveryType: {
          label: 'Тип доставки',
          placeholder: 'Выберите тип доставки',
        },
        resetFilters: 'Сбросить фильтр',
      },
    },
  },
};
