import { useQuery } from '@tanstack/vue-query';
import { getClientFiles } from '../api';

const useGetClientFilesByIdQuery = clientId => {
  return useQuery({
    queryKey: ['client-files', 'list', { clientId }],
    async queryFn({ queryKey }) {
      const { clientId } = queryKey[2];

      const response = await getClientFiles(clientId);

      return response.data;
    },
    retry: 0,
    gcTime: 0,
    staleTime: Infinity,
  });
};

export default useGetClientFilesByIdQuery;
