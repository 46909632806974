export default {
  features: {
    transferApplicationToAnotherStore: {
      button: 'Перекинуть на другой магазин',
      modal: {
        title: 'Перекинуть на другой магазин',
        store: {
          label: 'Магазин',
        },
        cancel: 'Отменить',
        submit: 'Сохранить',
      },
      successToast: {
        title: 'Успешно',
        subTitle: 'Заявка перекинут на указанный магазин',
      },
    },
  },
};
