export const DELIVERY_STATUS = {
  NEW: 'NEW',
  ORDER_ACCEPTED: 'ORDER_ACCEPTED',
  COURIER_PICKED_UP: 'COURIER_PICKED_UP',
  DELIVERED: 'DELIVERED',
  CANCELLED: 'CANCELLED',
  REFUND: 'REFUND',
};

export const DELIVERY_STATUS_ID = {
  NEW: 1,
  ORDER_ACCEPTED: 3,
  COURIER_PICKED_UP: 4,
  DELIVERED: 2,
  CANCELLED: 5,
};

export const DELIVERY_TYPE_SERVICE = {
  POINT_POINT: 'POINT_POINT',
  POINT_ENTER: 'POINT_ENTER',
  ENTER_ENTER: 'ENTER_ENTER',
  ENTER_POINT: 'ENTER_POINT',
};

export const DELIVERY_TYPE = {
  ALIF_DELIVERY: 'ALIF_DELIVERY',
  DPD_DELIVERY: 'DPD_DELIVERY',
  UZ_POST: 'UZ_POST',
  NESUVEZU: 'NESUVEZU',
  FARGO: 'FARGO',
};
