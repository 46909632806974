export default {
  widgets: {
    moderationRequestsList: {
      filters: {
        search: {
          placeholder: 'Поиск...',
        },
        createdAt: {
          placeholder: 'Дата создания',
        },
        clear: 'Сброс',
      },
      table: {
        headers: ['ID', 'КОЛ-ВО ТОВАРОВ', 'СТАТУС', 'ДАТА ДОБАВЛЕНИЯ', 'КТО ДОБАВИЛ'],
      },
    },
  },
};
