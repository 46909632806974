export default {
  features: {
    cancelModerationRequest: {
      button: 'Отменить',
      confirmModal: {
        title: 'Отмена',
        desc: 'Вы действительно хотите отменить операцию?',
        cancel: 'Закрыть',
        confirm: 'Подтвердить',
      },
    },
  },
};
