export default {
  features: {
    createReject: {
      selectItems: {
        title: 'Выберите товар для возврата',
      },
      selectReason: {
        title: 'Причина возврата',
        suggestedOptions: [
          'Товар не остался',
          'Вбили неправильный товар',
          'Бракованный товар',
          'Клиент отказался от товара',
          'Товар не доставили',
        ],
        other: 'Другое',
      },
      arizaUpload: {
        title: 'Загрузить заявление',
        dropdown: {
          title: 'Скачать шаблон',
          items: ['На узбексоком', 'На русском'],
        },
        alert:
          'По рассрочке уже произведена оплата. Скачайте шаблон заявления, который клиент должен заполнить и подписать, затем загрузите его обратно.',
        label: 'Заявление',
      },
      instructions: {
        title: 'Инструкция',
        list: ['Напишите причину возврата', 'Выберите товары', 'Загрузите заявление'],
      },
      confirm: {
        title: 'Оформление возврата',
        desc: 'Без вашей подписи или/и подписи клиента, заявка на возврат будет отменена',
        confirm: 'Оформить',
        modal: {
          title: 'Вы действительно хотите вернуть этот товар?',
          cancel: 'Отмена',
          submit: 'Подтвердить',
        },
      },
    },
  },
};
