export default {
  widgets: {
    clientCardsList: {
      title: 'Mijoz kartasi',
      addMore: 'Yana qo‘shish',
      rules: [
        'Asosiy kartadagi ism mijozning ismi bilan mos bo‘lishi kerak',
        'Limitni oshirish uchun bir nechta kartangizni kiritishingiz mumkin',
      ],
    },
  },
};
