export default {
  entities: {
    previewPayment: {
      generalInfo: {
        title: 'Ежемесячная оплата',
        sumPerMonth: 'сум / мес',
        months: 'Месяцев',
        commission: 'Комиссия',
        credit: 'Рассрочка',
      },
      paymentSchedule: {
        title: 'График платежей',
        headers: ['Дата', 'Остаток до', 'Платеж', 'Остаток после'],
      },
    },
  },
};
