export default {
  entities: {
    clientQuestionnaire: {
      form: {
        activityArea: {
          label: 'Сфера деятельности',
          placeholder: 'Выберите значение',
        },
        preferredLanguage: {
          label: 'Предпочитаемый язык',
          options: ['Узбекский', 'Русский'],
        },
        car: {
          label: 'Автомобиль',
          options: ['Есть', 'Нет'],
        },
      },
    },
  },
};
