export default {
  features: {
    createCredit: {
      desc: [
        'Оформление рассрочки',
        'Отправить смс для подтверждения выдачи рассрочки.',
        'После подтверждения',
        'рассрочку нельзя отменить!',
        'Вы теперь являетесь доверенным лицом Alif',
      ],
      create: 'Оформить',
    },
  },
};
