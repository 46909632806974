export default {
  features: {
    cancelApplication: {
      button: 'Bekor qilish',
      modal: {
        title: 'Bekor qilish',
        reason: {
          label: 'Iltimos, arizani bekor qilish sababini tanlang',
        },
        cancel: 'Bekor qilish',
        submit: 'Saqlash',
      },
      confirmModal: {
        title: 'Arizani bekor qilishga aminmisiz?',
        subTitle: "Joriy arizada siz tovarlarni, to'lov muddatini va sanasini o'zgartirishingiz mumkin.",
        cancel: 'Bekor qilish',
        confirm: 'Davom ettirish',
      },
    },
  },
};
