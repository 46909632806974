export default {
  features: {
    createReject: {
      selectItems: {
        title: 'Qaytarish tovarini tanlang',
      },
      selectReason: {
        title: 'Qaytarish sababi',
        suggestedOptions: [
          'Tovar qolmadi',
          "Noto'g'ri tovar kiritilgan",
          'Buzuq tovar',
          'Mijoz tovarni rad etdi',
          'Tovar yetkazib berilmadi',
        ],
        other: 'Boshqa',
      },
      arizaUpload: {
        title: 'Arizani yuklash',
        dropdown: {
          title: 'Shablonni yuklab olish',
          items: ['O‘zbek tilida', 'Rus tilida'],
        },
        alert:
          'Muddatli to‘lov bo‘yicha to‘lov amalga oshirilgan. Mijoz to‘ldirib, imzolashi kerak bo‘lgan ariza shablonini yuklab oling, so‘ng uni qayta yuklang.',
        label: 'Ariza',
      },
      instructions: {
        title: "Ko'rsatmalar",
        list: ['Qaytarish sababini kiriting', 'Tovarlarni tanlang', 'Arizani yuklang'],
      },
      confirm: {
        title: 'Qaytarishni rasmiylashtirish',
        desc: 'Sizning imzoingiz va/yoki mijoz imzosisiz qaytarish so‘rovi bekor qilinadi',
        confirm: 'Rasmiylashtirish',
        modal: {
          title: 'Ushbu tovarni qaytarishni istaysizmi?',
          cancel: 'Bekor qilish',
          submit: 'Tasdiqlash',
        },
      },
    },
  },
};
