<template>
  <div class="@container">
    <div class="grid @md:grid-cols-2 justify-center gap-2 @md:gap-4 relative text-sm text-center">
      <div class="rounded-lg flex flex-col justify-center items-center gap-3 bg-gray-100 dark:bg-gray-900 p-4">
        <div class="bg-white p-2 rounded-lg relative">
          <canvas ref="canvasEl" class="w-[152px] h-[152px]" />

          <div
            v-if="isQrLoading"
            class="rounded-lg absolute top-0 left-0 bottom-0 right-0 bg-white opacity-[0.8] dark:bg-gray-900 flex items-center justify-center"
          >
            <at-loading />
          </div>
        </div>
        <p class="font-semibold">
          {{ $t('entities.clientFile.scanQr') }}
        </p>
      </div>
      <div
        class="static w-fit h-fit max-h-[56px] max-w-[56px] mx-auto @md:mx-0 @md:absolute inset-1/2 @md:-translate-x-1/2 @md:-translate-y-1/2 rounded-full border-0 md:border-8 border-white dark:border-gray-800 p-4 py-6 md:py-4 flex items-center justify-center bg-gray-100 dark:bg-gray-900 uppercase text-gray-400"
      >
        {{ $t('entities.clientFile.or') }}
      </div>
      <div class="rounded-lg flex flex-col justify-center items-center gap-3 bg-gray-100 dark:bg-gray-900 p-4">
        <div class="rounded-full bg-white dark:bg-gray-800 text-green-600 p-2">
          <folder-add-icon class="w-6 text-primary-500" />
        </div>
        <p class="font-semibold">
          {{ $t('entities.clientFile.uploadFromDevice') }}
        </p>
        <div class="flex flex-col items-start">
          <at-button color="primary" type="button" :loading="isButtonLoading" @click="fileInput.click()">
            {{ $t('entities.clientFile.selectFileBtn') }}
          </at-button>

          <input
            ref="fileInput"
            hidden
            type="file"
            accept="image/png, image/jpeg, image/jpg"
            @change="handleFileChange"
          />
        </div>
      </div>
    </div>

    <crop-modal ref="cropModalComp" />
  </div>
</template>

<script setup>
  import { onMounted, ref } from 'vue';
  import { AtButton, AtLoading } from '@packages/aliftech-ui';
  import { FolderAddIcon } from '@heroicons/vue/solid';
  import QRCode from 'qrcode';
  import { CropModal } from '@/to-fsd/shared/ui';

  const props = defineProps({
    qrCodeMessage: {
      type: String,
      required: true,
    },
    isQrLoading: {
      type: Boolean,
      default: false,
    },
    isButtonLoading: {
      type: Boolean,
      default: false,
    },
    withCropper: {
      type: Boolean,
      required: false,
    },
  });

  const emit = defineEmits(['upload']);

  const fileInput = ref(null);
  const canvasEl = ref(null);
  const cropModalComp = ref(null);

  onMounted(() => {
    QRCode.toCanvas(canvasEl.value, props.qrCodeMessage, {
      errorCorrectionLevel: 'Q',
      type: 'image/png',
      quality: 0.5,
      margin: 1,
      color: {
        dark: '#000',
        light: '#fff',
      },
      width: 152,
      height: 152,
    });
  });

  const handleFileChange = async e => {
    const clientFile = e.target.files[0];
    fileInput.value.value = '';

    if (!clientFile) {
      return;
    }

    if (props.withCropper) {
      const croppedFile = await cropModalComp.value.open(clientFile);

      if (croppedFile) {
        emit('upload', croppedFile);
      }
    } else {
      emit('upload', clientFile);
    }
  };
</script>
