export default {
  pages: {
    applicationsShow: {
      pageTabs: ['О заявке', 'Детали и график оплаты'],
      applicationItemsTable: {
        title: 'Удаленные товары',
      },
      segregated: 'Продавец отделил и передал товар(ы) для Alif Moliya',
    },
  },
};
