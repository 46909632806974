export default {
  pages: {
    uploadClientFile: {
      desc: 'Загрузка файла',
      save: 'Сохранить',
      retry: 'Переснять',
      takePhoto: 'Сделать фото',
      successMessage: 'Файл загружен',
    },
  },
};
