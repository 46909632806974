<template>
  <router-view v-slot="{ Component }">
    <at-sidebar
      :items="items"
      :user="me"
      :logo="{
        name: 'Merchant Logo',
        path: '/Logo.svg',
        darkPath: '/LogoDark.svg',
      }"
      :user-dropdown-items="dropdownItems"
      :module="module"
      :modules="moduleOptions"
      :is-dark-mode="theme === 'dark'"
      :lang="lang"
      @update:lang="changeLang($event)"
      @update:is-dark-mode="setTheme($event ? 'dark' : 'light')"
      @update:module="changeModule"
      @on-logout="logout"
    >
      <div>
        <nasiya-notification-slider></nasiya-notification-slider>
      </div>

      <div class="pb-12">
        <component :is="Component"></component>
      </div>
    </at-sidebar>
  </router-view>
  <template v-if="isCentrifugeUsed">
    <nasiya-client-scoring-centrifuge></nasiya-client-scoring-centrifuge>
  </template>
  <template v-else>
    <nasiya-client-scoring-pubnub></nasiya-client-scoring-pubnub>
  </template>
</template>

<script setup>
  import { computed, defineAsyncComponent, provide } from 'vue';
  import { useRouter } from 'vue-router';
  import { useI18n } from 'vue-i18n/index';
  import { useService, useTheme } from '@/hooks';
  import authService from '@/modules/main/auth/services/auth.services';
  import { modulesList, modulesName } from '../../../main/common/constants/modules';
  import { ROUTE_NAMES as NASIYA_ROUTES } from '../constants/routeNames';
  import { ROUTE_NAMES as MAIN_ROUTES } from '../../../main/common/constants/routeNames';
  import { useUserStore } from '../../../main/user/stores';
  import { useMainStore } from '../../../main/common/stores';
  import { useNasiyaGlobalStore } from '../../common/stores';
  import { useProblemCaseStore } from '../../problems/stores';
  import { useRejectCaseStore } from '../../rejects/stores';
  import { resetStores } from '@/stores';
  import { storeToRefs } from 'pinia';
  import PubNub from 'pubnub';
  import { listeners } from '@/plugins/pubnub';
  import { useModuleOptions } from '../../../../hooks/useModuleOptions';
  import { useJivoFieldsAutoComplete } from '@/hooks/useJivoFieldsAutoComplete';
  import { useSubscribeToUpdateLocalStorage } from '@/hooks/useSubscribeToUpdateLocalStorage';
  import { useLang } from '@/to-fsd/shared/hooks';

  useJivoFieldsAutoComplete();

  const { t } = useI18n();
  const router = useRouter();

  const user = useUserStore();
  const store = useNasiyaGlobalStore();
  const mainStore = useMainStore();
  const problemCaseStore = useProblemCaseStore();
  const rejectCasesStore = useRejectCaseStore();

  const { theme, setTheme } = useTheme();
  const { lang, changeLang } = useLang();
  const { isCentrifugeUsed, storeId } = storeToRefs(store);
  const { module } = storeToRefs(mainStore);
  const moduleOptions = useModuleOptions();
  mainStore.$patch({ module: modulesName.NASIYA });

  useSubscribeToUpdateLocalStorage();

  const me = computed(() => {
    return {
      id: user?.me?.id,
      name: user?.me?.name,
      phone: user?.me?.phone,
      avatar_link: user?.me?.avatar_link,
      merchant_store_id: storeId.value,
      permissions: user?.me?.permissions,
    };
  });

  const isAllowedDpdDelivery = computed(() => store.merchantRelations?.store?.dpd_delivery);

  const newProblemsCount = computed(() => problemCaseStore.newProblemsCount);
  const onPartnerReviewRejectCasesCount = computed(() => rejectCasesStore.onPartnerReviewRejectsCount);

  const { execute: getNewProblemsCount } = useService(() => problemCaseStore.fetchNewProblemsCount());
  const { execute: getOnPartnerReviewRejectCases } = useService(() =>
    rejectCasesStore.fetchOnPartnerReviewRejectsCount()
  );

  getNewProblemsCount();
  getOnPartnerReviewRejectCases();

  const links = computed(() => {
    return [
      {
        title: t('sidebar.routes.main'),
        visible: true,
        permission: 'merchant_alifnasiya',
        icon: 'Home',
        to: { name: NASIYA_ROUTES.home },
      },
      {
        title: t('sidebar.routes.applications'),
        to: { name: NASIYA_ROUTES.applicationsList },
        permission: 'merchant_applications',
        icon: 'DocumentText',
        visible: true,
      },
      {
        title: t('sidebar.routes.searchClients'),
        visible: true,
        icon: 'Search',
        to: { name: NASIYA_ROUTES.clientsSearch },
      },
      {
        title: t('sidebar.routes.rejects'),
        to: { name: NASIYA_ROUTES.rejectsList },
        permission: 'merchant_applications',
        icon: 'Refresh',
        count: onPartnerReviewRejectCasesCount.value > 0 ? onPartnerReviewRejectCasesCount.value : null,
        badgeColor: 'red',
        visible: true,
      },
      {
        title: t('sidebar.routes.tickets'),
        to: { name: NASIYA_ROUTES.problemsList },
        permission: 'merchant_applications',
        icon: 'Exclamation',
        visible: true,
        count: newProblemsCount.value > 0 ? newProblemsCount.value : null,
        badgeColor: 'red',
      },
      ...(isAllowedDpdDelivery.value
        ? [
            {
              title: t('sidebar.routes.deliveries'),
              permission: 'merchant_deliveries',
              visible: true,
              icon: 'Truck',
              to: { name: NASIYA_ROUTES.deliveriesList },
            },
          ]
        : []),
      {
        title: t('sidebar.routes.requests'),
        permission: 'merchant_moderation_goods',
        visible: true,
        icon: 'ClipboardCheck',
        to: { name: NASIYA_ROUTES.moderationRequests },
      },
      {
        title: t('sidebar.routes.warehouse'),
        permission: 'merchant_upload_goods',
        visible: true,
        icon: 'ShoppingBag',
        to: { name: NASIYA_ROUTES.warehouseList },
      },
      {
        title: t('sidebar.routes.management'),
        permission: 'merchant_manager',
        visible: true,
        icon: 'Briefcase',
        routes: [
          { title: t('sidebar.routes.analytics'), to: { name: NASIYA_ROUTES.analyticsList, query: { tab: 'sales' } } },
          { title: t('sidebar.routes.invoice'), to: { name: NASIYA_ROUTES.invoiceList } },
          { title: t('sidebar.routes.sales'), to: { name: NASIYA_ROUTES.salesListToFsd } },
          { title: t('sidebar.routes.excels'), to: { name: NASIYA_ROUTES.excelsList } },
          { title: t('sidebar.routes.allApplications'), to: { name: NASIYA_ROUTES.managerApplicationsListToFsd } },
        ],
      },
      {
        title: t('sidebar.routes.notifications'),
        visible: true,
        icon: 'Bell',
        permission: 'merchant_applications',
        to: { name: NASIYA_ROUTES.notificationsList },
      },
      {
        title: t('sidebar.routes.bonuses'),
        visible: true,
        icon: 'Cash',
        to: { name: NASIYA_ROUTES.bonusesIndex },
      },
      {
        title: t('sidebar.routes.installmentPayment'),
        visible: true,
        icon: 'CreditCard',
        to: { name: NASIYA_ROUTES.installmentPayment },
      },
    ];
  });

  const nasiyaGlobalStore = useNasiyaGlobalStore();

  const hasAccessToBonuses = computed(() => {
    return nasiyaGlobalStore.merchantRelations.merchant_settings.pays_bonus;
  });

  const items = computed(() => {
    return links.value?.filter?.(link => {
      const isBonusesLink = link.to?.name === NASIYA_ROUTES.bonusesIndex;

      if (isBonusesLink && !hasAccessToBonuses.value) {
        return false;
      }

      return (
        (link.visible && me.value?.permissions?.find?.(el => el.name === link.permission || !link.permission)) || false
      );
    });
  });

  const dropdownItems = computed(() => {
    return [
      {
        title: t('sidebar.logout'),
        icon: { name: 'logout', type: 'outline' },
        onClick: () => logout(),
      },
    ];
  });

  const logout = () => {
    authService.logout().then(() => {
      router.push({ name: MAIN_ROUTES.login }).then(() => {
        resetStores();
      });
    });
  };

  const changeModule = value => {
    mainStore.$patch({ module: value });
    router.push({ name: modulesList[value].homeRoute });
  };

  const registerPubnub = () => {
    const merchantStoreId = me.value?.merchant_store_id;
    const pubnub = new PubNub({
      publishKey: process.env.VUE_APP_PUBNUB_PUBKEY,
      subscribeKey: process.env.VUE_APP_PUBNUB_SUBKEY,
      uuid: String(merchantStoreId),
      autoNetworkDetection: true,
      presenceTimeout: 3000,
    });
    pubnub.addListener(listeners);
    window.addEventListener('unload', () => {
      pubnub.removeListener(listeners);
      pubnub.unsubscribeAll();
    });
    return pubnub;
  };

  const pubnub = registerPubnub();
  provide('$pubnub', pubnub);

  const AtSidebar = defineAsyncComponent(() => import('@/plugins/aliftech-ui/components/AtSidebar/AtSidebar'));
  const NasiyaNotificationSlider = defineAsyncComponent(() =>
    import('../../notifications/components/NasiyaNotificationSlider')
  );
  const NasiyaClientScoringCentrifuge = defineAsyncComponent(() =>
    import('../../clients/components/NasiyaClientScoringCentrifuge')
  );
  const NasiyaClientScoringPubnub = defineAsyncComponent(() =>
    import('../../clients/components/NasiyaClientScoringPubnub')
  );
</script>
