export default {
  features: {
    createCredit: {
      desc: [
        "Muddatli to'lovni rasmiylashtirish",
        "Muddatli to'lovni tasdiqlash uchun sms-kodni yuborish.",
        'Tasdiqlashdan keyin',
        "muddatli to'lovni bekor qilib bo'lmaydi!",
        'Endikda siz Alifning vakili sifatida ish boshlaysiz',
      ],
      create: 'Rasmiylashtirish',
    },
  },
};
