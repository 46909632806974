import { MODERATION_REQUEST_STATUS } from '../constants';

export default {
  entities: {
    moderationRequest: {
      statusText: {
        [MODERATION_REQUEST_STATUS.PROCESSING]: 'На обработке',
        [MODERATION_REQUEST_STATUS.CANCELLED]: 'Отменено',
        [MODERATION_REQUEST_STATUS.COMPLETED]: 'Одобрено',
        [MODERATION_REQUEST_STATUS.UNDER_CORRECTION]: 'На исправлении',
      },
      moderationRequestStatusUpdateSubscription: {
        toastText: {
          [MODERATION_REQUEST_STATUS.COMPLETED]: 'сСтатус запроса изменился на: Одобрено',
          [MODERATION_REQUEST_STATUS.CANCELLED]: 'Статус запроса изменился на: Отменено',
          [MODERATION_REQUEST_STATUS.UNDER_CORRECTION]: 'Статус запроса изменился на: На исправлении',
          [MODERATION_REQUEST_STATUS.PROCESSING]: 'Статус запроса изменился на: На обработке',
        },
      },
    },
  },
};
