export default {
  features: {
    createDelivery: {
      panelLink: {
        title: 'Yetkazmalar',
        link: 'Alif yetkazmasi',
      },
    },
  },
};
