export default {
  widgets: {
    moderationRequestItemsTable: {
      title: 'Нужно исправить',
      headers: ['', 'НАИМЕНОВАНЕ / СКУ', 'КАТЕГОРИЯ / ИКПУ', 'КОЛ-ВО / ЦЕНА (СУМ)', 'НУЖНО ИСПРАВИТЬ'],
      times: 'шт.',
      paginationInfo: 'Показано от {from} до {to} из {total} результатов',
    },
  },
};
