export default {
  pages: {
    applicationsShow: {
      pageTabs: ['Ariza haqida', "To'lov grafik va tafsilotlari"],
      applicationItemsTable: {
        title: 'Qaytarilgan tovarlar',
      },
      segregated: 'Sotuvchi Alif Moliya uchun mahsulot(lar)ni ajratdi va topshirdi',
    },
  },
};
