export default {
  features: {
    sellToAlif: {
      sellToAlifPanel: {
        desc: [
          'ALIF MOLIYA MCHJ TAKLIFI',
          '“Alif Moliya” MChJ arizada ko‘rsatilgan mahsulot(lar)ni kelishilgan narxda sotib oladi. “Alifga sotish” tugmasini bosish orqali siz ushbu taklifni qabul qilasiz va arizada ko‘rsatilgan mahsulotlarga egalik huquqini “Alif Moliya” MChJga o‘tkazasiz.',
        ],
        button: 'Alifga sotish',
      },
      cancelSellToAlifPanel: {
        desc: "Ushbu maxsulotlarning egalik huquqi «Alif Moliya» MCHJ ga muvaffaqiyatli o'tdi",
        button: 'Bekor qilish',
      },
    },
  },
};
