export default {
  entities: {
    clientQuestionnaire: {
      form: {
        activityArea: {
          label: 'Faoliyat sohasi',
          placeholder: 'Faoliyat sohasi tanlang',
        },
        preferredLanguage: {
          label: 'Tanlangan til',
          options: ["O'zbek tili", 'Rus tili'],
        },
        car: {
          label: 'Avtomobil',
          options: ['Bor', "Yo'q"],
        },
      },
    },
  },
};
