import { MODERATION_REQUEST_STATUS } from '../constants';

export default {
  entities: {
    moderationRequest: {
      statusText: {
        [MODERATION_REQUEST_STATUS.PROCESSING]: "Ko'rib chiqilmoqda",
        [MODERATION_REQUEST_STATUS.CANCELLED]: 'Bekor qilindi',
        [MODERATION_REQUEST_STATUS.COMPLETED]: 'Tasdiqlandi',
        [MODERATION_REQUEST_STATUS.UNDER_CORRECTION]: 'Tuzatishda',
      },
      moderationRequestStatusUpdateSubscription: {
        toastText: {
          [MODERATION_REQUEST_STATUS.COMPLETED]: 'Soʻrov holati quyidagicha oʻzgartirildi: Tasdiqlandi',
          [MODERATION_REQUEST_STATUS.CANCELLED]: 'Soʻrov holati quyidagicha oʻzgartirildi: Bekor qilindi',
          [MODERATION_REQUEST_STATUS.UNDER_CORRECTION]: 'Soʻrov holati quyidagicha oʻzgartirildi: Tuzatishda',
          [MODERATION_REQUEST_STATUS.PROCESSING]: "Soʻrov holati quyidagicha oʻzgartirildi: Ko'rib chiqilmoqda",
        },
      },
    },
  },
};
