export default {
  features: {
    cancelModerationRequest: {
      button: 'Bekor qilish',
      confirmModal: {
        title: 'Bekor qilish',
        desc: 'Haqiqatan ham amalni bekor qilmoqchimisiz?',
        cancel: 'Yopish',
        confirm: 'Tasdiqlash',
      },
    },
  },
};
