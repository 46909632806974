export default {
  features: {
    clientUpdatePassport: {
      passport: {
        title: 'Mijozning pasporti',
        uploadPhotoMessage: 'Mijoz pasportining fotosuratini yuklang',
        fields: {
          passportPhoto: {
            label: 'Pasport fotosurati',
          },
          passportFace: {
            label: 'Yuz fonidagi pasport fotosurati',
          },
        },
        continue: 'Davom etish',
      },
      idPassport: {
        title: 'ID karta',
        uploadPhotoMessage: 'Mijoz ID kartasining fotosuratini yuklang',
        fields: {
          passportIdPhotoFront: {
            label: 'ID kartaning old tomoni',
          },
          passportIdPhotoBack: {
            label: 'ID kartaning orqa tomoni',
          },
          passportIdPhoto: {
            label: 'ID karta mijoz yuzi yonida',
          },
        },
        submit: 'Davom etish',
      },
      passportData: {
        gender: {
          M: 'Erkak',
          F: 'Ayol',
        },
        fields: {
          passportId: {
            label: 'Seriya va raqam',
          },
          pinfl: {
            label: 'PINFL',
          },
          region: {
            label: 'Hudud',
          },
          address: {
            label: 'Roʻyxatga olish manzili',
          },
          nationalityName: {
            label: 'Fuqarolik',
          },
          birthplace: {
            label: 'Tugʻilgan joyi',
          },
          issueDate: {
            label: 'Berilgan sana',
            placeholder: 'yil-oy-kun',
          },
          expiresDate: {
            label: 'Amal qilish muddati',
            placeholder: 'yil-oy-kun',
          },
          issuedBy: {
            label: 'Kim tomonidan berilgan',
            placeholder: 'Pasport kim tomonidan berilgan',
          },
          name: {
            label: 'Ism',
          },
          surname: {
            label: 'Familiya',
          },
          patronymic: {
            label: 'Otasining ismi',
          },
          gender: {
            label: 'Jinsi',
          },
          birthDate: {
            label: 'Tugʻilgan sana',
          },
          cityName: {
            label: 'Tugʻilgan joyi',
          },
        },
        submit: 'Saqlash',
        cancel: 'Bekor qilish',
        fetchOcr: 'Fotosuratdan qayta o‘qish',
      },
      successAlert: "Pasport ma'lumotlari muvaffaqiyatli yangilandi.",
    },
  },
};
