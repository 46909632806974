export default {
  widgets: {
    clientCardsList: {
      title: 'Карта клиента',
      addMore: 'Добавить ещё',
      rules: [
        'Имя на основной карте должно совпадать с именем клиента',
        'Можно добавить несколько карт, для увелечения лимита рассрочки',
      ],
    },
  },
};
