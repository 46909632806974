export default {
  entities: {
    applicationItem: {
      table: {
        title: 'Tovarlar',
        totalSum: 'Jami',
        headers: ['Nomi', 'Sku', 'Narxi', 'Soni'],
        link: 'Ariza haqida batafsil',
      },
    },
  },
};
