export default {
  features: {
    createHoldDownPayment: {
      button: "Boshlang'ich to'lov kiritish",
      modal: {
        title: "Boshlang'ich to'lov kiritish",
        subTitle: "Boshlang'ich to'lov summasi xarid narxi va mijoz limiti o'rtasidagi farqdan kam bo'lmasligi kerak",
        amount: {
          label: "Boshlang'ich to'lov miqdori",
          placeholder: '0',
          alert: "Istalgan to'lov summasi",
        },
        cancel: 'Bekor qilish',
        save: "To'lash",
      },
      successAlert: "To'lov muvaffaqiyatli bajarildi",
    },
  },
};
