export default {
  widgets: {
    moderationRequestItemsTable: {
      title: 'Tuzatish kerak',
      headers: ['', 'NOMI / SKU', 'TOIFA / IKPU', 'MIQDORI / NARXI (SUM)', 'Tuzatish kerak'],
      times: 'ta.',
      paginationInfo: "Natijalar {from} dan {to} gacha {total} ta ko'rsatilmoqda",
    },
  },
};
