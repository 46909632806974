export default {
  features: {
    deleteModerationRequestItem: {
      confirmModal: {
        title: "O'chirish",
        desc: "Siz rostdan ham ushbu mahsulotni o'chirishni istaysizmi?",
        cancel: 'Bekor qilish',
        confirm: "O'chirish",
      },
    },
  },
};
