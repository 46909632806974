export default {
  widgets: {
    clientFile: {
      commonFiles: {
        title: 'Qoʻshimcha fayllar',
        notMandatory: 'Majburiy emas',
        noFiles: "Boshqa fayllar yo'q",
        addNew: 'Qoʻshimcha fayl yuklash',
      },
    },
  },
};
