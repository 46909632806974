export default {
  pages: {
    moderationRequests: {
      title: 'Запросы',
      dropdown: {
        label: 'Добавить товар',
        items: ['Добавить вручную', 'Добавить через Excel'],
      },
    },
  },
};
