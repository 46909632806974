export default {
  entities: {
    applicationItem: {
      table: {
        title: 'Товары',
        totalSum: 'Общая сумма',
        headers: ['Наименование', 'Sku', 'Цена', 'Кол-во'],
        link: 'Подробнее о заявке',
      },
    },
  },
};
