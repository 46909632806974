export default {
  pages: {
    updateClientPassport: {
      documentTitle: 'Pasportni yangilash',
      pageTitle: 'Pasportni yangilash',
      updatePassportFiles: {
        passport: {
          title: 'Mijozning pasporti',
          uploadPhotoMessage: 'Mijoz pasportining fotosuratini yuklang',
          fields: {
            passportPhoto: {
              label: 'Pasport fotosurati',
            },
            passportFace: {
              label: 'Yuz fonidagi pasport fotosurati',
            },
          },
          continue: 'Davom etish',
        },
        idPassport: {
          title: 'ID karta',
          uploadPhotoMessage: 'Mijoz ID kartasining fotosuratini yuklang',
          fields: {
            passportIdPhotoFront: {
              label: 'ID kartaning old tomoni',
            },
            passportIdPhotoBack: {
              label: 'ID kartaning orqa tomoni',
            },
            passportIdPhoto: {
              label: 'ID karta mijoz yuzi yonida',
            },
          },
          submit: 'Davom etish',
        },
      },
    },
  },
};
