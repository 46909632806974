export default {
  widgets: {
    deliveriesListTable: {
      headers: ['ID', 'Mijoz', 'Yetkazib berish turi', 'Holat', 'Sana'],
      useSearchToFindCompletedApplicationsAlert: 'Rasmiylashtirilgan arizalarni topish uchun qidiruvdan foydalaning',
      filtersBlock: {
        deliveryType: {
          label: 'Yetkazib berish turi',
          placeholder: 'Tur bo‘yicha filtr',
        },
        resetFilters: "Filtrlarni qayta o'rnatish",
      },
    },
  },
};
