export default {
  widgets: {
    clientFile: {
      commonFiles: {
        title: 'Дополнительные файлы',
        notMandatory: 'Необязательно',
        noFiles: 'Нет прочих файлов',
        addNew: 'Добавить дополнительный файл',
      },
    },
  },
};
