import { CLIENT_STATUS } from '../constants';

export default {
  entities: {
    client: {
      statusText: {
        [CLIENT_STATUS.REVIEWED]: 'Ko`rib chiqilmoqda',
        [CLIENT_STATUS.CREATED]: 'Yangi',
        [CLIENT_STATUS.FIXED]: 'Tuzatishda',
        [CLIENT_STATUS.IDENTIFIED]: 'A’zo',
      },
      table: {
        title: 'Mijozlar',
        headers: ['Mijoz', 'Yaratilgan', 'Holat'],
        noClientsAlert: 'Bugun uchun mijozlar yo‘q',
      },
      fixReasons: {
        title: 'Ma’lumotlarni tuzatish kerak',
      },
    },
  },
};
