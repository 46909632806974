export default {
  features: {
    applicationItemsFillImeis: {
      panel: {
        title: 'IMEI',
        desc: 'У некоторых товаров отсутствует IMEI, заполните ее вручную',
        openModal: 'Заполнить IMEI',
      },
      modal: {
        title: 'Укажите IMEI для следующих товаров',
        alert: 'IMEI код должен состоять из 15 цифр',
      },
      form: {
        firstImei: {
          placeholder: 'IMEI 1',
        },
        secondImei: {
          placeholder: 'IMEI 2',
        },
      },
      successMessage: 'IMEI успешно сохранена',
    },
  },
};
