export default {
  entities: {
    rejectCase: {
      rejectionType: {
        full: 'Butun',
        partial: 'Qisman',
      },
      info: {
        title: "Qaytarish haqida ma'lumot",
        clientName: 'Mijoz F.I.Sh.',
        clientPhone: 'Mijoz raqami',
        date: 'Yuborilgan sanasi',
        note: 'Qaytarish sababi',
      },
      table: {
        title: 'Qaytarish tovarlari',
        headers: ['Nomi', 'Narxi'],
      },
      cancelReason: {
        title: 'Qaytarish rad etish sababi',
      },
      photos: {
        title: 'Tovar rasmi',
        alert: 'Tovar rasmi mavjud emas',
      },
      rejectCasesTable: {
        title: 'Qaytarishlar',
        allItems: 'Barcha tovarlar',
        fullReject: 'Butun',
        partialReject: 'Qisman',
        noRejects: 'Qaytarishlar mavjud emas',
        headers: ['Tovarlar nomi', 'Holat', 'Turi', 'Sana'],
      },
    },
  },
};
