export default {
  features: {
    applicationItemsFillMarking: {
      panel: {
        title: 'Маркировка',
        desc: 'У некоторых товаров отсутствует маркировка, заполните ее вручную',
        openModal: 'Заполнить маркировку',
      },
      modal: {
        title: 'Укажите маркировку для следующих товаров',
        alert: 'Маркировка должна состоять из минимум 38 символов (первые 18 символов должны быть цифрами)',
        successAlert: 'Маркировка успешно сохранена',
      },
      form: {
        marking: {
          placeholder: 'Маркировка',
        },
      },
    },
  },
};
