export default {
  widgets: {
    moderationRequestsList: {
      filters: {
        search: {
          placeholder: 'Qidirish...',
        },
        createdAt: {
          placeholder: 'Yaratilgan sana',
        },
        clear: 'Tozalash',
      },
      table: {
        headers: ['ID', 'Mahsulotlar soni', 'Holati', "Qo'shilgan sana", "Kim qo'shdi"],
      },
    },
  },
};
