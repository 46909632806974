export default {
  pages: {
    clientsShow: {
      secondaryTitle: 'Клиент',
      tabs: ['Требования', 'Заявки'],
      clientRequirements: {
        fixReasons: {
          title: 'Надо поправить данные',
        },
        requirementsList: ['Файлы', 'Карта', 'Анкета'],
        sendToReview: 'Отправить на рассмотрение',
        filesTab: {
          alreadyUploaded: 'Загружено',
          idPassportPhoto: {
            title: 'Фото ID паспорта',
          },
        },
        questionnaireTab: {
          contacts: {
            title: 'Контакты',
            fields: {
              contactNumber: {
                label: 'Номер телефона',
              },
              person: {
                label: 'Кем является',
              },
              ownerName: {
                label: 'Имя владельца',
              },
            },
            addOneMoreContact: 'Добавить ещё контакт',
            save: 'Сохранить',
            edit: 'Изменить',
            cancel: 'Отмена',
          },
          labels: {
            car: 'Автомобиль',
            hasCar: 'Есть',
            hasNoCar: 'Нет',
          },
        },
      },
    },
  },
};
