export default {
  features: {
    editModerationRequestItem: {
      modal: {
        title: "Tovar o'zgartirish",
        cancel: 'Bekor qilish',
        confirm: 'Tasdiqlash',
      },
    },
  },
};
