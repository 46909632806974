import { DELIVERY_STATUS, DELIVERY_TYPE, DELIVERY_TYPE_SERVICE } from '../constants';

export default {
  entities: {
    delivery: {
      generateFargoWaybill: 'Скачать накладную',
      statusText: {
        [DELIVERY_STATUS.NEW]: 'Новый',
        [DELIVERY_STATUS.ORDER_ACCEPTED]: 'Заказ принят',
        [DELIVERY_STATUS.COURIER_PICKED_UP]: 'Курьер забрал',
        [DELIVERY_STATUS.DELIVERED]: 'Доставлено',
        [DELIVERY_STATUS.CANCELLED]: 'Отменено',
      },
      deliveryType: {
        all: 'Все',
        [DELIVERY_TYPE.ALIF_DELIVERY]: 'Alif Delivery',
        [DELIVERY_TYPE.DPD_DELIVERY]: 'DPD',
        [DELIVERY_TYPE.FARGO]: 'Fargo',
        [DELIVERY_TYPE.UZ_POST]: 'Uzpost',
        [DELIVERY_TYPE.NESUVEZU]: 'NesuVezu',
      },
      deliveryInfo: {
        title: 'Доставки',
        deliveryNumber: 'Доставка №{number}',
        modal: {
          title: 'Доставка №{number}',
          timeToDelivery: 'Приблизительное время доставки',
          senderAddress: 'Адрес отправителя:',
          receiverAddress: 'Адрес получателя:',
          closeButton: 'Понятно',
        },
      },
      dpdDeliveryTypeService: {
        [DELIVERY_TYPE_SERVICE.ENTER_ENTER]: 'C двери до двери',
        [DELIVERY_TYPE_SERVICE.ENTER_POINT]: 'C двери до пункт выдачи заказа',
        [DELIVERY_TYPE_SERVICE.POINT_POINT]: 'C пункт выдачи заказа до пункт выдачи заказа',
        [DELIVERY_TYPE_SERVICE.POINT_ENTER]: 'C пункт выдачи заказа до двери',
      },
      deliveryReceiverInfo: {
        name: 'Имя клиента',
        phone: 'Телефоны',
      },
      approximateTime: {
        text: 'Приблизительное время доставки',
      },
      senderAndReceiverAddress: {
        sender: 'Адрес отправителя',
        receiver: 'Адрес получателя',
      },
      deliveryCreatedAlert: {
        panel: {
          title: 'Доставка Алифа',
        },
        alert: 'Для данной заявки была создана доставка',
      },
    },
  },
};
