import { useI18n } from 'vue-i18n';
import { useToast } from '@/to-fsd/shared/hooks';

const useShowFileUploadedToast = () => {
  const { t } = useI18n();
  const toast = useToast();

  return () => {
    toast.info({
      title: t('entities.clientFile.fileUploadedMessage'),
      duration: 5000,
      id: 'applicant-file-uploaded',
    });
  };
};

export default useShowFileUploadedToast;
