export default {
  features: {
    deleteModerationRequestItem: {
      confirmModal: {
        title: 'Удаление',
        desc: 'Вы действительно хотите удалить товар?',
        cancel: 'Отмена',
        confirm: 'Удалить',
      },
    },
  },
};
