export default {
  features: {
    editModerationRequestItem: {
      modal: {
        title: 'Изменение товара',
        cancel: 'Отмена',
        confirm: 'Подтвердить',
      },
    },
  },
};
