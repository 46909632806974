export default {
  features: {
    applicationItemsFillImeis: {
      panel: {
        title: 'IMEI',
        desc: "Ba'zi mahsulotlarda IMEI kod yo'q, ularni qo'lda to'ldiring",
        openModal: "IMEI to'ldirish",
      },
      modal: {
        title: "Quyidagi mahsulotlar uchun IMEI kodni to'ldiring",
        alert: "IMEI kod 15 ta raqamlardan iborat bo'lishi kerak",
      },
      form: {
        firstImei: {
          placeholder: 'IMEI 1',
        },
        secondImei: {
          placeholder: 'IMEI 2',
        },
      },
      successMessage: 'IMEI muvaffaqiyati saqlandi',
    },
  },
};
