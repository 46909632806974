export default {
  entities: {
    moderationRequestItem: {
      form: {
        name: {
          label: 'Nomi',
        },
        ikpu: {
          label: 'IKPU',
        },
        price: {
          label: 'Narxi',
        },
        sku: {
          label: 'SKU',
        },
        quantity: {
          label: 'Miqdori',
        },
        thirdCategory: {
          label: 'Toifasi',
        },
        gtin: {
          label: 'Shtrixkod',
          search: 'Qidirish',
        },
        searchByGtin: "Shtrixkod bo'yicha qidirish",
      },
    },
  },
};
