export default {
  pages: {
    uploadClientFile: {
      desc: 'Fayl yuklash',
      save: 'Saqlash',
      retry: 'Qayta suratga olish',
      takePhoto: 'Suratga olish',
      successMessage: 'Fayl yuklandi',
    },
  },
};
