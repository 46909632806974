export default {
  features: {
    cancelApplication: {
      button: 'Отменить',
      modal: {
        title: 'Отменить',
        reason: {
          label: 'Пожалуйста, выберите причину отмены заявки',
        },
        cancel: 'Отменить',
        submit: 'Сохранить',
      },
      confirmModal: {
        title: 'Вы уверены?',
        subTitle: 'Вы можете продолжить эту заявку, изменив товары, срок и дату погашения без создания новой заявки.',
        cancel: 'Отмена',
        confirm: 'Продолжить',
      },
    },
  },
};
