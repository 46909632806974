<template>
  <router-view v-slot="{ Component }">
    <at-sidebar
      :items="items"
      :user="me"
      :logo="{
        name: 'Merchant Logo',
        path: '/Logo.svg',
        darkPath: '/LogoDark.svg',
      }"
      :user-dropdown-items="dropdownItems"
      :module="module"
      :modules="moduleOptions"
      :is-dark-mode="theme === 'dark'"
      :lang="lang"
      @update:lang="changeLang($event)"
      @update:is-dark-mode="setTheme($event ? 'dark' : 'light')"
      @update:module="changeModule"
      @on-logout="logout"
    >
      <div class="pb-12">
        <component :is="Component"></component>
      </div>
    </at-sidebar>
  </router-view>

  <reload-page-on-deploy-modal />
</template>

<script setup>
  import { computed, defineAsyncComponent } from 'vue';
  import { useRouter } from 'vue-router';
  import { useI18n } from 'vue-i18n/index';
  import authService from '@/modules/main/auth/services/auth.services';
  import { modulesList, modulesName } from '../../../main/common/constants/modules';
  import { ROUTE_NAMES as MAIN_ROUTES } from '@/modules/main/common/constants/routeNames';
  import { ROUTE_NAMES as ADMIN_ROUTES } from '../constants/routeNames';
  import { useUserStore } from '../../../main/user/stores';
  import { useMainStore } from '../../../main/common/stores';
  import { resetStores } from '@/stores';
  import { storeToRefs } from 'pinia';
  import { useModuleOptions } from '../../../../hooks/useModuleOptions';
  import { useSubscribeToUpdateLocalStorage } from '@/hooks/useSubscribeToUpdateLocalStorage';
  import { useJivoFieldsAutoComplete } from '@/hooks/useJivoFieldsAutoComplete';
  import { useTheme } from '@/hooks';
  import { useLang } from '@/to-fsd/shared/hooks';
  import { ReloadPageOnDeployModal } from '@/to-fsd/features/reload-page-on-deploy';

  useJivoFieldsAutoComplete();

  const { t } = useI18n();
  const router = useRouter();

  const { theme, setTheme } = useTheme();
  const { lang, changeLang } = useLang();
  const user = useUserStore();
  const store = useMainStore();
  const { module } = storeToRefs(store);
  const moduleOptions = useModuleOptions();
  store.$patch({ module: modulesName.ADMIN });

  useSubscribeToUpdateLocalStorage();

  const me = computed(() => {
    return {
      name: user?.me?.name,
      phone: user?.me?.phone,
      avatar_link: user?.me?.avatar_link,
    };
  });

  const links = computed(() => {
    return [
      {
        title: t('sidebar.routes.employees'),
        visible: true,
        icon: 'Users',
        to: { name: ADMIN_ROUTES.employeesList },
      },
      {
        title: t('sidebar.routes.pos'),
        visible: true,
        icon: 'Qrcode',
        to: { name: ADMIN_ROUTES.posList },
      },
    ];
  });

  const items = computed(() => {
    return links.value?.filter?.(link => {
      return link.visible || false;
    });
  });

  const dropdownItems = computed(() => {
    return [
      {
        title: t('sidebar.logout'),
        icon: { name: 'logout', type: 'outline' },
        onClick: () => logout(),
      },
    ];
  });

  const logout = () => {
    authService.logout().then(() => {
      router.push({ name: MAIN_ROUTES.login }).then(() => {
        resetStores();
      });
    });
  };

  const changeModule = value => {
    store.$patch({ module: value });
    router.push({ name: modulesList[value].homeRoute });
  };

  const AtSidebar = defineAsyncComponent(() => import('@/plugins/aliftech-ui/components/AtSidebar/AtSidebar'));
</script>
