export default {
  features: {
    applicationItemsFillMarking: {
      panel: {
        title: 'Belgi',
        desc: "Ba'zi mahsulotlarda belgisi yo'q, ularni qo'lda to'ldiring",
        openModal: "Belgini to'ldirish",
      },
      modal: {
        title: "Quyidagi mahsulotlar uchun belgini to'ldiring",
        alert: "Kod kamida 38 belgidan iborat bo'lishi kerak (birinchi 18 ta belgi raqamlar bo'lishi kerak)",
        successAlert: 'Belgi muvaffaqiyati saqlandi',
      },
      form: {
        marking: {
          placeholder: 'Belgi',
        },
      },
    },
  },
};
