export default {
  widgets: {
    requestApplicationApprovalPanel: {
      title: 'Barcha qadamlar tugallandi',
      signed: 'Shartnoma imzolandi. Imzolagan — {name}.',
      canBeSent: "Ko'rib chiqish uchun yuborilishingiz mumkin.",
      hintDownPayment:
        "Agar limit ushbu tovarni sotib olish uchun yetarli bo'lmasa — mijozga boshlang'ich to'lovni taklif qiling",
    },
  },
};
