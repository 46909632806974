import { CLIENT_FILE_TYPE } from '@/to-fsd/entities/client-file';

export default {
  entities: {
    clientFile: {
      fileTypeLabel: {
        [CLIENT_FILE_TYPE.ID_PASSPORT_FACE]: 'ID карта на фоне лица',
        [CLIENT_FILE_TYPE.PASSPORT_FACE]: 'Паспорт на фоне лица',
        [CLIENT_FILE_TYPE.TAXI_DRIVER_DAILY_INCOME]: 'Доход водителя за день',
        [CLIENT_FILE_TYPE.TAXI_DRIVER_WEEKLY_INCOME]: 'Доход водителя за неделю',
        [CLIENT_FILE_TYPE.TAXI_DRIVER_MONTHLY_INCOME]: 'Доход водителя за месяц',
        [CLIENT_FILE_TYPE.MILITARY_CERTIFICATE]: 'Удостоверение',
        [CLIENT_FILE_TYPE.PASSPORT]: 'Фото паспорта',
        [CLIENT_FILE_TYPE.ID_PASSPORT_FRONT]: 'Фото передней стороны ID карты',
        [CLIENT_FILE_TYPE.ID_PASSPORT_BACK]: 'Фото задней стороны ID карты',
        [CLIENT_FILE_TYPE.COMMON]: 'Дополнительный файл',
        [CLIENT_FILE_TYPE.MONEY_TRANSFER_RECIPIENT]: 'Получатель денежных переводов',
      },
      fileUploadedMessage: 'Файл клиента загружен',
      scanQr: 'Отсканируйте QR код для загрузки фото через телефон',
      or: 'или',
      uploadFromDevice: 'Загрузите файл с устройства',
      selectFileBtn: 'Выбрать файл',
    },
  },
};
