export default {
  entities: {
    previewPayment: {
      generalInfo: {
        title: "Oylik to'lov",
        sumPerMonth: "so'm / oy",
        months: 'Oy',
        commission: 'Ustama',
        credit: "Muddatli to'lov",
      },
      paymentSchedule: {
        title: "To'lov jadvali",
        headers: ['Sana', "To'lovdan oldin qoldiq", "To'lov", "To'lovdan keyin qoldiq"],
      },
    },
  },
};
