import { mainConnect, generateError } from '@/services';

export const ordersService = {
  /**
   * Get orders
   *
   * @param {Object} params
   * @param {String} params.status_id - status id
   * @param {String} params.q - search
   *
   * @returns {Promise<Orders>}
   * @typedef {Object} Orders
   * @property {Number} data[].id - id
   * @property {String} data[].client.name - client name
   * @property {String} data[].client.phone - client phone
   * @property {String} data[].status.class - status class
   * @property {String} data[].status.name_ru - status name_ru
   * @property {String} data[].status.name_uz - status name_uz
   * @property {String} data[].items[].total_amount - total amount
   * @property {String} data[].items[].created_at - created at
   * @property {Number} meta.last_page - last page
   *
   * @throws {Error}
   */
  getOrders(params) {
    return mainConnect.get(`orders`, { params }).catch(generateError);
  },

  /**
   * Get order
   *
   * @param {Number} id - order id
   *
   * @returns {Promise<Order>}
   * @typedef {Object} Order
   * @property {Object} data
   * @property {Number} data.id - id
   * @property {String} data.client.name - client name
   * @property {String} data.client.phone - client phone
   * @property {Number} data.status.id - status id
   * @property {String} data.status.key - status key
   * @property {String} data.payment_method.key - payment method key
   * @property {String} data.delivery_address - delivery address
   * @property {String} data.delivery_note - delivery note
   * @property {String} data.delivery_method - delivery method
   * @property {String} data.created_at - created at
   * @property {String} data.order_delivery - delivery
   * @property {String} data.order_delivery.id_from_service_delivery - delivery created id
   *
   * @throws {Error}
   */
  getOrder(id) {
    return mainConnect.get(`orders/${id}`).catch(generateError);
  },

  /**
   * Request store order
   *
   * @param {Number} id - id
   *
   * @returns {Promise<RequestStoreOrder>}
   * @typedef {Object} RequestStoreOrder
   *
   * @throws {Error}
   */
  requestStoreOrder(id) {
    return mainConnect.post(`orders/payments/${id}/request`).catch(generateError);
  },

  /**
   * Store order
   *
   * @param {Number} id - id
   * @param {Object} params
   * @param {String} params.otp - otp
   *
   * @returns {Promise<StoreOrder>}
   * @typedef {Object} StoreOrder
   *
   * @throws {Error}
   */
  storeOrder(id, params) {
    return mainConnect.post(`orders/payments/${id}/verify`, params).catch(generateError);
  },

  /**
   * Get order statuses
   *
   * @returns {Promise<OrderStatuses>}
   * @typedef {Array} OrderStatuses
   *
   * @throws {Error}
   */
  getOrderStatuses(params) {
    return mainConnect.get(`orders/statuses`, { params }).catch(generateError);
  },

  /**
   * set marking code to order item
   * @param {String} orderId
   * @param {String} itemId
   * @param {Object} body
   * @param {String} body.marking_code
   * @returns {Promise}
   */
  setMarkingCodeToOrderItem(orderId, itemId, body) {
    return mainConnect.post(`alifshop/orders/${orderId}/items/${itemId}/set-marking-code`, body).catch(generateError);
  },

  /**
   *
   * @param {Object} body
   * @param {Array<String>} body.codes
   * @returns {Promise}
   */
  checkMarkingCode(body) {
    return mainConnect.post('applications/applications/application_items/check-marking', body).catch(generateError);
  },

  /**
   *
   * @param {String} orderId
   * @returns {Promise}
   */
  approveOrder(orderId) {
    return mainConnect.post(`alifshop/orders/${orderId}/approve`).catch(generateError);
  },

  /**
   *
   * @param {String} orderId
   * @returns {Promise}
   */
  rejectOrder(orderId) {
    return mainConnect.post(`alifshop/orders/${orderId}/reject`).catch(generateError);
  },

  /**
   *
   * @param {String} orderId
   * @param {Object} body
   * @returns {Promise}
   */
  createOrderDelivery(orderId, body) {
    return mainConnect.post(`alifshop/orders/${orderId}/deliveries`, body).catch(generateError);
  },
};
