export default {
  features: {
    transferApplicationToAnotherStore: {
      button: "Boshqa do'konga o'tkazish",
      modal: {
        title: "Boshqa do'konga o'tkazish",
        store: {
          label: "Do'kon",
        },
        cancel: 'Bekor qilish',
        submit: 'Saqlash',
      },
      successToast: {
        title: "O'tkazildi",
        subTitle: "Ariza boshqa do'konga muvaffaqiyatli o'tkazildi",
      },
    },
  },
};
