import useGetClientFileQuery from './queries/useGetClientFileQuery';
import useGetClientFilesByIdQuery from './queries/useGetClientFilesByIdQuery';
import useUploadClientFileByUuidMutation from './mutations/useUploadClientFileByUuidMutation';
import { getClientFile } from './api';
import { CLIENT_FILE_TYPE } from './constants';
import { clientFileGetFileTypeKey, getFileTypeLabel } from './lib';
import ClientFilePreview from './ui/ClientFilePreview.vue';
import ClientFileUploader from './ui/ClientFileUploader.vue';
import useShowFileUploadedToast from './hooks/useShowFileUploadedToast';

const api = {
  getClientFile,
};

export {
  CLIENT_FILE_TYPE,
  useGetClientFileQuery,
  useGetClientFilesByIdQuery,
  useUploadClientFileByUuidMutation,
  api,
  clientFileGetFileTypeKey,
  ClientFilePreview,
  ClientFileUploader,
  useShowFileUploadedToast,
  getFileTypeLabel,
};
