import { CLIENT_STATUS } from '../constants';

export default {
  entities: {
    client: {
      statusText: {
        [CLIENT_STATUS.REVIEWED]: 'На рассмотрении',
        [CLIENT_STATUS.CREATED]: 'Новый',
        [CLIENT_STATUS.FIXED]: 'На исправлении',
        [CLIENT_STATUS.IDENTIFIED]: 'Аъзо',
      },
      table: {
        title: 'Клиенты',
        headers: ['Клиент', 'Создано', 'Статус'],
        noClientsAlert: 'Клиентов за сегодня нет',
      },
      fixReasons: {
        title: 'Надо поправить данные',
      },
    },
  },
};
