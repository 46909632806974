export default {
  pages: {
    clientsShow: {
      secondaryTitle: 'Mijoz',
      tabs: ['Talablar', 'Arizalar'],
      clientRequirements: {
        fixReasons: {
          title: 'Maʼlumotlarni tuzatish kerak',
        },
        requirementsList: ['Fayllar', 'Karta', 'Soʻrovnoma'],
        sendToReview: 'Ko‘rib chiqishga yuborish',
        filesTab: {
          alreadyUploaded: 'Yuklangan',
          idPassportPhoto: {
            title: 'ID pasport fotosurati',
          },
        },
        questionnaireTab: {
          contacts: {
            title: 'Kontaktlar',
            fields: {
              contactNumber: {
                label: 'Telefon raqami',
              },
              person: {
                label: 'Kim hisoblanadi',
              },
              ownerName: {
                label: 'Egasining ismi',
              },
            },
            addOneMoreContact: 'Yana bir kontakt qoʻshish',
            save: 'Saqlash',
            edit: 'Tahrirlash',
            cancel: 'Bekor qilish',
          },
          labels: {
            car: 'Avtomobil',
            hasCar: 'Bor',
            hasNoCar: 'Yoq',
          },
        },
      },
    },
  },
};
